/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: @env.CI_COMMIT_REF_NAME@
 */
export type ClearSetupSession409 = {[key: string]: any};

export type ClearSetupSession401 = {[key: string]: any};

export type ClearSetupSession400 = {[key: string]: any};

export type ClearSetupSessionParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type Debug409 = {[key: string]: any};

export type Debug401 = {[key: string]: any};

export type Debug400 = {[key: string]: any};

export type DebugParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type CreatePreviewFilename409 = {[key: string]: any};

export type CreatePreviewFilename401 = {[key: string]: any};

export type CreatePreviewFilename400 = {[key: string]: any};

export type CreatePreviewFilenameParams = {
  pattern: string;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetFilenamePatter409 = {[key: string]: any};

export type GetFilenamePatter401 = {[key: string]: any};

export type GetFilenamePatter400 = {[key: string]: any};

export type GetFilenamePatterParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetCompanyList409 = {[key: string]: any};

export type GetCompanyList401 = {[key: string]: any};

export type GetCompanyList400 = {[key: string]: any};

export type GetCompanyListParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetEnabledModules409 = {[key: string]: any};

export type GetEnabledModules401 = {[key: string]: any};

export type GetEnabledModules400 = {[key: string]: any};

export type GetEnabledModulesParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetConnectorCount409 = {[key: string]: any};

export type GetConnectorCount401 = {[key: string]: any};

export type GetConnectorCount400 = {[key: string]: any};

export type GetConnectorCountParams = {
  subCompany?: string[];
};

export type GetConfiguration409 = {[key: string]: any};

export type GetConfiguration401 = {[key: string]: any};

export type GetConfiguration400 = {[key: string]: any};

export type GetConfigurationParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetUserConfig409 = {[key: string]: any};

export type GetUserConfig401 = {[key: string]: any};

export type GetUserConfig400 = {[key: string]: any};

export type GetUserConfigParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type Get1401 = {[key: string]: any};

export type Get1400 = {[key: string]: any};

export type Get1Params = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type ListApiConnectors401 = {[key: string]: any};

export type ListApiConnectors400 = {[key: string]: any};

export type ListApiConnectorsParams = {
  subCompany?: string[];
};

export type Authorized409 = {[key: string]: any};

export type Authorized401 = {[key: string]: any};

export type Authorized400 = {[key: string]: any};

export type AuthorizedParams = {
  code: string;
  state: string;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetConnectorDetails1409 = {[key: string]: any};

export type GetConnectorDetails1401 = {[key: string]: any};

export type GetConnectorDetails1400 = {[key: string]: any};

export type GetConnectorDetails1Params = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetConnectorDetails409 = {[key: string]: any};

export type GetConnectorDetails401 = {[key: string]: any};

export type GetConnectorDetails400 = {[key: string]: any};

export type GetConnectorDetailsParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetConnector401 = {[key: string]: any};

export type GetConnector400 = {[key: string]: any};

export type GetConnectorParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type ListMasterdataConnectors401 = {[key: string]: any};

export type ListMasterdataConnectors400 = {[key: string]: any};

export type ListMasterdataConnectorsParams = {
  subCompany?: string[];
};

export type Get401 = {[key: string]: any};

export type Get400 = {[key: string]: any};

export type GetParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type ListSftpServerConnectors401 = {[key: string]: any};

export type ListSftpServerConnectors400 = {[key: string]: any};

export type ListSftpServerConnectorsParams = {
  subCompany?: string[];
};

export type GetPartnerList409 = {[key: string]: any};

export type GetPartnerList401 = {[key: string]: any};

export type GetPartnerList400 = {[key: string]: any};

export type GetPartnerListParams = {
  subCompany?: string[];
};

export type GetDocTypeClassifications409 = {[key: string]: any};

export type GetDocTypeClassifications401 = {[key: string]: any};

export type GetDocTypeClassifications400 = {[key: string]: any};

export type GetDocTypeClassificationsParams = {
  ediRelationFilterAndPageableDTO: EDIRelationFilterAndPageableDTO;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetEDIPartners409 = {[key: string]: any};

export type GetEDIPartners401 = {[key: string]: any};

export type GetEDIPartners400 = {[key: string]: any};

export type GetEDIPartnersParams = {
  ediRelationFilterAndPageableDTO: EDIRelationFilterAndPageableDTO;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetServiceTypes409 = {[key: string]: any};

export type GetServiceTypes401 = {[key: string]: any};

export type GetServiceTypes400 = {[key: string]: any};

export type GetServiceTypesParams = {
  ediRelationFilterAndPageableDTO: EDIRelationFilterAndPageableDTO;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetStatusList409 = {[key: string]: any};

export type GetStatusList401 = {[key: string]: any};

export type GetStatusList400 = {[key: string]: any};

export type GetStatusListParams = {
  ediRelationFilterAndPageableDTO: EDIRelationFilterAndPageableDTO;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetEDIRelations409 = {[key: string]: any};

export type GetEDIRelations401 = {[key: string]: any};

export type GetEDIRelations400 = {[key: string]: any};

export type GetEDIRelationsParams = {
  ediRelationFilterAndPageableDTO: EDIRelationFilterAndPageableDTO;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type EvaluateBooleanFlag1409 = {[key: string]: any};

export type EvaluateBooleanFlag1401 = {[key: string]: any};

export type EvaluateBooleanFlag1400 = {[key: string]: any};

export type EvaluateBooleanFlag1Params = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type EvaluateBooleanFlag409 = {[key: string]: any};

export type EvaluateBooleanFlag401 = {[key: string]: any};

export type EvaluateBooleanFlag400 = {[key: string]: any};

export type EvaluateBooleanFlagParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type EvaluateObjectFlag1409 = {[key: string]: any};

export type EvaluateObjectFlag1401 = {[key: string]: any};

export type EvaluateObjectFlag1400 = {[key: string]: any};

export type EvaluateObjectFlag1Params = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type EvaluateObjectFlag409 = {[key: string]: any};

export type EvaluateObjectFlag401 = {[key: string]: any};

export type EvaluateObjectFlag400 = {[key: string]: any};

export type EvaluateObjectFlagParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetCount409 = {[key: string]: any};

export type GetCount401 = {[key: string]: any};

export type GetCount400 = {[key: string]: any};

export type GetCountParams = {
  query?: string;
  status?: FilterMessageState;
  searchAfter?: string;
  pageSize?: number;
  createdTo?: string;
  createdFrom?: string;
  documentTypeId?: string;
  searchType?: MessageSearchType;
  sortField?: string;
  sortOrder?: string;
  subCompany?: string[];
  dateFilterType?: DateFilterType;
};

export type DownloadFullMessages409 = {[key: string]: any};

export type DownloadFullMessages401 = {[key: string]: any};

export type DownloadFullMessages400 = {[key: string]: any};

export type DownloadFullMessagesParams = {
  query?: string;
  status?: FilterMessageState;
  searchAfter?: string;
  pageSize?: number;
  createdTo?: string;
  createdFrom?: string;
  documentTypeId?: string;
  searchType?: MessageSearchType;
  sortField?: string;
  sortOrder?: string;
  subCompany?: string[];
  dateFilterType?: DateFilterType;
  selectAll?: boolean;
  messageIds?: string[];
  excludedMessageIds?: string[];
  includeAcknowledgment?: boolean;
};

export type GetS3MessageHeader409 = {[key: string]: any};

export type GetS3MessageHeader401 = {[key: string]: any};

export type GetS3MessageHeader400 = {[key: string]: any};

export type GetS3MessageHeaderParams = {
  statusInformationId: string;
  messageId: string;
  headerName: string;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetUserArchiveStatus409 = {[key: string]: any};

export type GetUserArchiveStatus401 = {[key: string]: any};

export type GetUserArchiveStatus400 = {[key: string]: any};

export type GetUserArchiveStatusParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type DownloadUserArchiveEntity409 = {[key: string]: any};

export type DownloadUserArchiveEntity401 = {[key: string]: any};

export type DownloadUserArchiveEntity400 = {[key: string]: any};

export type DownloadUserArchiveEntityParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type List409 = {[key: string]: any};

export type List401 = {[key: string]: any};

export type List400 = {[key: string]: any};

export type ListParams = {
  subCompany?: string[];
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type DownloadAttachment409 = {[key: string]: any};

export type DownloadAttachment401 = {[key: string]: any};

export type DownloadAttachment400 = {[key: string]: any};

export type DownloadAttachmentParams = {
  attachment: MessageStatusAttachmentDto;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type DownloadAcknowledgement409 = {[key: string]: any};

export type DownloadAcknowledgement401 = {[key: string]: any};

export type DownloadAcknowledgement400 = {[key: string]: any};

export type DownloadAcknowledgementParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type DownloadMessageContent409 = {[key: string]: any};

export type DownloadMessageContent401 = {[key: string]: any};

export type DownloadMessageContent400 = {[key: string]: any};

export type DownloadMessageContentParams = {
  request: StatusInformationDownloadRequest;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type RenderStatusInformation409 = {[key: string]: any};

export type RenderStatusInformation401 = {[key: string]: any};

export type RenderStatusInformation400 = {[key: string]: any};

export type RenderStatusInformationParams = {
  mme: MessageMonitoring;
  locale: string;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetMessage409 = {[key: string]: any};

export type GetMessage401 = {[key: string]: any};

export type GetMessage400 = {[key: string]: any};

export type GetMessageParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetMessages409 = {[key: string]: any};

export type GetMessages401 = {[key: string]: any};

export type GetMessages400 = {[key: string]: any};

export type GetMessagesParams = {
  query?: string;
  status?: FilterMessageState;
  searchAfter?: string;
  pageSize?: number;
  createdTo?: string;
  createdFrom?: string;
  documentTypeId?: string;
  searchType?: MessageSearchType;
  sortField?: string;
  sortOrder?: string;
  subCompany?: string[];
  dateFilterType?: DateFilterType;
};

export type UpdateAndFetchUserNewsFeedbackConfiguration409 = {
  [key: string]: any;
};

export type UpdateAndFetchUserNewsFeedbackConfiguration401 = {
  [key: string]: any;
};

export type UpdateAndFetchUserNewsFeedbackConfiguration400 = {
  [key: string]: any;
};

export type UpdateAndFetchUserNewsFeedbackConfigurationParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetSSOToken409 = {[key: string]: any};

export type GetSSOToken401 = {[key: string]: any};

export type GetSSOToken400 = {[key: string]: any};

export type GetSSOTokenParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetDocumentTypeClassifications409 = {[key: string]: any};

export type GetDocumentTypeClassifications401 = {[key: string]: any};

export type GetDocumentTypeClassifications400 = {[key: string]: any};

export type GetDocumentTypeClassificationsParams = {
  subCompany?: string[];
};

export type GetDocumentTypeDistribution409 = {[key: string]: any};

export type GetDocumentTypeDistribution401 = {[key: string]: any};

export type GetDocumentTypeDistribution400 = {[key: string]: any};

export type GetDocumentTypeDistributionParams = {
  period: Period;
  documentTypeClassification?: string;
  direction?: Direction;
  subCompany?: string[];
  ediPartner?: string;
};

export type GetEdiPartnerData409 = {[key: string]: any};

export type GetEdiPartnerData401 = {[key: string]: any};

export type GetEdiPartnerData400 = {[key: string]: any};

export type GetEdiPartnerDataParams = {
  period: Period;
  documentTypeClassification?: string;
  direction?: Direction;
  subCompany?: string[];
  ediPartner?: string;
};

export type GetExceptions409 = {[key: string]: any};

export type GetExceptions401 = {[key: string]: any};

export type GetExceptions400 = {[key: string]: any};

export type GetExceptionsParams = {
  subCompany?: string[];
};

export type GetFixedExceptions409 = {[key: string]: any};

export type GetFixedExceptions401 = {[key: string]: any};

export type GetFixedExceptions400 = {[key: string]: any};

export type GetFixedExceptionsParams = {
  subCompany?: string[];
};

export type GetMessageHistogram409 = {[key: string]: any};

export type GetMessageHistogram401 = {[key: string]: any};

export type GetMessageHistogram400 = {[key: string]: any};

export type GetMessageHistogramParams = {
  period: Period;
  documentTypeClassification?: string;
  direction?: Direction;
  subCompany?: string[];
  ediPartner?: string;
};

export type GetMessageStatisticsCsv409 = {[key: string]: any};

export type GetMessageStatisticsCsv401 = {[key: string]: any};

export type GetMessageStatisticsCsv400 = {[key: string]: any};

export type GetMessageStatisticsCsvParams = {
  splitOnAlias?: boolean;
  showSentAndReceived?: boolean;
  mode?: string;
  companyUuid?: string;
  period: Period;
  documentTypeClassification?: string;
  direction?: Direction;
  subCompany?: string[];
  ediPartner?: string;
};

export type GetMessageStatistics409 = {[key: string]: any};

export type GetMessageStatistics401 = {[key: string]: any};

export type GetMessageStatistics400 = {[key: string]: any};

export type GetMessageStatisticsParams = {
  splitOnAlias?: boolean;
  showSentAndReceived?: boolean;
  mode?: string;
  companyUuid?: string;
  period: Period;
  documentTypeClassification?: string;
  direction?: Direction;
  subCompany?: string[];
  ediPartner?: string;
};

export type GetPartnersAndMappingsData409 = {[key: string]: any};

export type GetPartnersAndMappingsData401 = {[key: string]: any};

export type GetPartnersAndMappingsData400 = {[key: string]: any};

export type GetPartnersAndMappingsDataParams = {
  subCompany?: string[];
};

export type GetSubCompanies409 = {[key: string]: any};

export type GetSubCompanies401 = {[key: string]: any};

export type GetSubCompanies400 = {[key: string]: any};

export type GetSubCompaniesParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetUser409 = {[key: string]: any};

export type GetUser401 = {[key: string]: any};

export type GetUser400 = {[key: string]: any};

export type GetUserParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type RegenerateUserKey1401 = {[key: string]: any};

export type RegenerateUserKey1400 = {[key: string]: any};

export type RegenerateUserKey1Params = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type AuthorizeClient409 = {[key: string]: any};

export type AuthorizeClient401 = {[key: string]: any};

export type AuthorizeClient400 = {[key: string]: any};

export type AuthorizeClientParams = {
  connectorUuid: string;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type Save1409 = {[key: string]: any};

export type Save1401 = {[key: string]: any};

export type Save1400 = {[key: string]: any};

export type Save1Params = {
  connectorUuid: string;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetEInvoiceConnectorsList1409 = {[key: string]: any};

export type GetEInvoiceConnectorsList1401 = {[key: string]: any};

export type GetEInvoiceConnectorsList1400 = {[key: string]: any};

export type GetEInvoiceConnectorsList1Params = {
  subCompany?: string[];
};

export type Save409 = {[key: string]: any};

export type Save401 = {[key: string]: any};

export type Save400 = {[key: string]: any};

export type Save200 = {[key: string]: any};

export type SaveParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetEInvoiceConnectorsList409 = {[key: string]: any};

export type GetEInvoiceConnectorsList401 = {[key: string]: any};

export type GetEInvoiceConnectorsList400 = {[key: string]: any};

export type GetEInvoiceConnectorsListParams = {
  subCompany?: string[];
};

export type RegenerateUserKey401 = {[key: string]: any};

export type RegenerateUserKey400 = {[key: string]: any};

export type RegenerateUserKeyParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type RegeneratePassword401 = {[key: string]: any};

export type RegeneratePassword400 = {[key: string]: any};

export type RegeneratePasswordParams = {
  subCompany?: string;
};

export type Resend409 = {[key: string]: any};

export type Resend401 = {[key: string]: any};

export type Resend400 = {[key: string]: any};

export type ResendParams = {
  lastStatusChange: string;
  forceUnlock?: boolean;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type GetMessageToResend409 = {[key: string]: any};

export type GetMessageToResend401 = {[key: string]: any};

export type GetMessageToResend400 = {[key: string]: any};

export type GetMessageToResendParams = {
  request: StatusInformationDownloadRequest;
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type SetHandledCustomer409 = {[key: string]: any};

export type SetHandledCustomer401 = {[key: string]: any};

export type SetHandledCustomer400 = {[key: string]: any};

export type SetHandledCustomer200 = {[key: string]: any};

export type SetHandledCustomerParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type UpdateSelectedSubCompanyDefault = {[key: string]: any};

export type UpdateSelectedSubCompany409 = {[key: string]: any};

export type UpdateSelectedSubCompany401 = {[key: string]: any};

export type UpdateSelectedSubCompany400 = {[key: string]: any};

export type UpdateSelectedSubCompanyParams = {
  /**
   * One ore more company UUIDs the resource should be filtered by
   */
  subCompany?: string[];
};

export type PathPatternKeyword =
  (typeof PathPatternKeyword)[keyof typeof PathPatternKeyword];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PathPatternKeyword = {
  MESSAGE_ID: 'MESSAGE_ID',
  MESSAGE_STATUS: 'MESSAGE_STATUS',
  DOCUMENT_TYPE: 'DOCUMENT_TYPE',
  DOCUMENT_SUB_TYPE: 'DOCUMENT_SUB_TYPE',
  DOCUMENT_TYPE_NAME: 'DOCUMENT_TYPE_NAME',
  DOCUMENT_REFERENCE: 'DOCUMENT_REFERENCE',
  TEST_INDICATOR: 'TEST_INDICATOR',
  RECEIVER_GLN: 'RECEIVER_GLN',
  RECEIVER_UUID: 'RECEIVER_UUID',
  RECEIVER_NAME: 'RECEIVER_NAME',
  RECEIVER_COMPANY_NAME: 'RECEIVER_COMPANY_NAME',
  RECEIVER_ID_IN_MESSAGE: 'RECEIVER_ID_IN_MESSAGE',
  SENDER_GLN: 'SENDER_GLN',
  SENDER_UUID: 'SENDER_UUID',
  SENDER_NAME: 'SENDER_NAME',
  SENDER_ID_IN_MESSAGE: 'SENDER_ID_IN_MESSAGE',
  EXTENSION: 'EXTENSION',
  MIME_TYPE: 'MIME_TYPE',
  TIMESTAMP: 'TIMESTAMP',
  IN_FILE_TOKEN_1: 'IN_FILE_TOKEN_1',
  IN_FILE_TOKEN_2: 'IN_FILE_TOKEN_2',
  IN_FILE_TOKEN_3: 'IN_FILE_TOKEN_3',
  IN_FILE_TOKEN_4: 'IN_FILE_TOKEN_4',
  IN_FILE_TOKEN_5: 'IN_FILE_TOKEN_5',
  OFTP2_IN_VFN: 'OFTP2_IN_VFN',
  RANDOM_NUMERIC_1: 'RANDOM_NUMERIC_1',
  RANDOM_NUMERIC_8: 'RANDOM_NUMERIC_8',
  RANDOM_ALPHANUMERIC_1: 'RANDOM_ALPHANUMERIC_1',
  RANDOM_ALPHANUMERIC_8: 'RANDOM_ALPHANUMERIC_8',
  yyyy: 'yyyy',
  MM: 'MM',
  dd: 'dd',
  HH: 'HH',
  mm: 'mm',
  ss: 'ss',
  SSS: 'SSS',
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  DAY: 'DAY'
} as const;

export interface PathPatternDto {
  filenamePattern?: string;
  keywords?: PathPatternKeyword[];
  uuid?: string;
  version?: number;
}

export interface CompanyNode {
  leaf?: boolean;
  level?: number;
  name?: string;
  subCompanies?: CompanyNode[];
  uuid?: string;
}

export type MessageStatus = (typeof MessageStatus)[keyof typeof MessageStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageStatus = {
  RECEIVED: 'RECEIVED',
  PERSISTENCEQUEUE: 'PERSISTENCEQUEUE',
  DELIVERED: 'DELIVERED',
  PICKEDUP: 'PICKEDUP',
  FORWARDQUEUE: 'FORWARDQUEUE',
  FORWARDED: 'FORWARDED',
  FORWARDED_PART: 'FORWARDED_PART',
  REVOKED: 'REVOKED',
  EXCEPTION: 'EXCEPTION',
  EXCEPTION_CUSTOMER: 'EXCEPTION_CUSTOMER',
  ARCHIVED: 'ARCHIVED',
  CONVERTED: 'CONVERTED',
  SIGNED: 'SIGNED',
  APPLIED_SCRIPT: 'APPLIED_SCRIPT',
  RESENT: 'RESENT',
  SEND_RETRY: 'SEND_RETRY',
  DRAFT: 'DRAFT',
  HANDLED: 'HANDLED',
  HANDLED_CUSTOMER: 'HANDLED_CUSTOMER',
  REQUEST_RECEIVED: 'REQUEST_RECEIVED',
  DECODED: 'DECODED',
  PREPARED_FOR_SENDING: 'PREPARED_FOR_SENDING',
  PROCESSED: 'PROCESSED',
  EXCEPTION_UNDER_REVIEW: 'EXCEPTION_UNDER_REVIEW',
  ONBOARDING: 'ONBOARDING',
  MASTERDATA_REQUEST_RECEIVED: 'MASTERDATA_REQUEST_RECEIVED',
  DELIVERED_WEB: 'DELIVERED_WEB',
  DELIVERED_API: 'DELIVERED_API',
  DELIVERED_SFTP: 'DELIVERED_SFTP',
  PICKEDUP_UNACK: 'PICKEDUP_UNACK',
  SENT_UNACK: 'SENT_UNACK',
  SENT_UNACK_LONGWAIT: 'SENT_UNACK_LONGWAIT',
  SPLIT: 'SPLIT',
  MERGE_WAIT: 'MERGE_WAIT',
  MERGED: 'MERGED',
  PARKED: 'PARKED',
  HALTED: 'HALTED',
  HANDOVER: 'HANDOVER',
  PARTNER_SWITCH: 'PARTNER_SWITCH'
} as const;

export interface MonitorConfiguration {
  readonly ackDownloadEnabled: boolean;
  companyType: CompanyType;
  readonly documentRenderingConfigurations: readonly DocumentRenderingConfiguration[];
  readonly ediRelationsPageEnabled: boolean;
  einvoiceConnectorSetupEnabled?: boolean;
  readonly owningCompanyName: string;
  readonly owningCompanyUuid: string;
  readonly partnerMatrixEnabled: boolean;
  readonly partnerMatrixTicketDetailsPageEnabled: boolean;
  resendState: MessageStatus;
  resendWithMutationState: MessageStatus;
  readonly supportChatEnabled: boolean;
  readonly supportTicketDetailsPageEnabled: boolean;
  readonly supportTicketPageEnabled: boolean;
}

export type DocumentRenderingConfigurationPdfRenderingTemplates = {
  [key: string]: string;
};

export interface DocumentRenderingConfiguration {
  readonly displayName: string;
  readonly documentTypeId: string;
  readonly downloadButtonIcon?: string;
  readonly downloadButtonTranslationKey: string;
  readonly downloadFileExtension: string;
  readonly mappingTypeUUids?: string[];
  readonly outputContentType: string;
  readonly pdfRenderingTemplates?: DocumentRenderingConfigurationPdfRenderingTemplates;
  readonly restrictedPartnerUuids?: string[];
  readonly uuid?: string;
}

export interface SupportedLocale {
  readonly flag?: string;
  readonly label?: string;
  readonly locale?: string;
}

export interface PartnerUserDto {
  readonly authorities?: string[];
  readonly userId?: string;
}

export interface CompanyNodeDto {
  readonly hasParent: boolean;
  readonly level: number;
  readonly name: string;
  readonly subCompanies: readonly CompanyNodeDto[];
  readonly uuid: string;
}

export interface Company {
  readonly companyType: CompanyType;
  readonly name: string;
  readonly uuid: string;
}

export interface PartnerRelation {
  readonly authorities: readonly string[];
  readonly customer: Company;
  readonly users: readonly PartnerUserDto[];
}

export interface UserConfiguration {
  authorities: string[];
  company: Company;
  companyNode: CompanyNodeDto;
  credentialsAuthAllowed?: boolean;
  credentialsAuthSupported?: boolean;
  readonly ediRelationsEnabled: boolean;
  readonly email: string;
  readonly eventSourceEnabled: boolean;
  readonly firstName: string;
  readonly lastName: string;
  mfaEnforcedOnUser?: boolean;
  partnerRelations: PartnerRelation[];
  selectedSubCompany: string;
  selectedSubCompanyName: string;
  readonly supportChatEnabled: boolean;
  readonly supportedLocales: readonly SupportedLocale[];
  readonly theme: string;
  readonly timeZone: string;
  readonly userLocale?: string;
  readonly userUuid: string;
}

export interface ApiConnectorDto {
  appKey?: string;
  companyName?: string;
  companyUuid?: string;
  connector?: Connector;
  copyOf?: string;
  direction?: ConnectorType;
  maxMessageSize?: number;
  mode?: number;
  requestCallback?: boolean;
  userId?: string;
  userKey?: string;
  uuid: string;
  version: number;
}

export interface EInvoiceConnectorListItemDto {
  country?: Country;
  state?: EInvoiceConnectorSetupState;
  uuid?: string;
}

export interface MasterdataConnectorDto {
  appKey?: string;
  companyName?: string;
  companyUuid?: string;
  connector?: Connector;
  direction?: ConnectorType;
  shkConnect?: boolean;
  userId?: string;
  userKey?: string;
  uuid: string;
  version?: number;
}

export type AuthenticationMethod =
  (typeof AuthenticationMethod)[keyof typeof AuthenticationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthenticationMethod = {
  PASSWORD: 'PASSWORD',
  CERTIFICATE: 'CERTIFICATE'
} as const;

export interface SftpServerConnectorDto {
  appKey?: string;
  authenticationMethod?: AuthenticationMethod;
  companyUuid?: string;
  connector: Connector;
  copyOf?: string;
  direction: ConnectorType;
  downloadDir?: string;
  maxFileSize?: number;
  password?: string;
  pickedUpOnDelete?: boolean;
  uploadDir?: string;
  username?: string;
  uuid: string;
  version: number;
}

export interface NameAndUuidOnly {
  name?: string;
  uuid?: string;
}

export interface EdiPartnerNames {
  data?: NameAndUuidOnly[];
}

export interface CompanyExtendedReference {
  name?: string;
  uuid?: string;
  version?: number;
}

export interface EDIRelationsSearchResponseDTO {
  ediRelations?: EDIRelationDTO[];
  totalCount?: number;
}

export type EDIRelationDirection =
  (typeof EDIRelationDirection)[keyof typeof EDIRelationDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EDIRelationDirection = {
  IN: 'IN',
  OUT: 'OUT'
} as const;

export type EDIRelationDTOSortValuesItem = {[key: string]: any};

export interface EDIRelationDTO {
  readonly companyName: string;
  readonly companyUuid: string;
  readonly docType: string;
  ediRelationDirection: EDIRelationDirection;
  readonly id: string;
  readonly lastUpdated: string;
  readonly partnerName: string;
  readonly partnerUuid: string;
  readonly serviceType: string;
  readonly sortValues: readonly EDIRelationDTOSortValuesItem[];
  readonly status: string;
}

export type EDIRelationFilterAndPageableDTOSearchAfterItem = {
  [key: string]: any;
};

export interface EDIRelationFilterAndPageableDTO {
  direction?: string;
  docType?: string[];
  pageSize?: number;
  partners?: string[];
  partnerSearch?: string;
  searchAfter?: EDIRelationFilterAndPageableDTOSearchAfterItem[];
  serviceType?: string[];
  sortOrder?: string;
  status?: string[];
  subCompany?: string[];
}

export interface ImmutableMetadata {
  [key: string]: any;
}

export type ErrorCode = (typeof ErrorCode)[keyof typeof ErrorCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorCode = {
  PROVIDER_NOT_READY: 'PROVIDER_NOT_READY',
  FLAG_NOT_FOUND: 'FLAG_NOT_FOUND',
  PARSE_ERROR: 'PARSE_ERROR',
  TYPE_MISMATCH: 'TYPE_MISMATCH',
  TARGETING_KEY_MISSING: 'TARGETING_KEY_MISSING',
  INVALID_CONTEXT: 'INVALID_CONTEXT',
  GENERAL: 'GENERAL'
} as const;

export interface ProviderEvaluationBoolean {
  errorCode?: ErrorCode;
  errorMessage?: string;
  flagMetadata?: ImmutableMetadata;
  reason?: string;
  value?: boolean;
  variant?: string;
}

export interface Value {
  boolean?: boolean;
  instant?: boolean;
  list?: boolean;
  null?: boolean;
  number?: boolean;
  string?: boolean;
  structure?: boolean;
}

export interface ProviderEvaluationValue {
  errorCode?: ErrorCode;
  errorMessage?: string;
  flagMetadata?: ImmutableMetadata;
  reason?: string;
  value?: Value;
  variant?: string;
}

export interface MessageCountDto {
  currentlyReindexing?: boolean;
  documentCount?: number;
  messageCount?: number;
}

export type State = (typeof State)[keyof typeof State];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const State = {
  QUEUED: 'QUEUED',
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED'
} as const;

export interface UserArchiveRequestEntity {
  archiveSize?: number;
  batchDownloadAsArchive?: boolean;
  companyName?: string;
  companyUuid?: string;
  direction?: Direction;
  downloadUrl?: string;
  entryPattern?: string;
  error?: string;
  from?: string;
  includeAcknowledgment?: boolean;
  md5Hash?: string;
  messageIds?: string[];
  numFilesArchived?: number;
  numMessagesArchived?: number;
  state?: State;
  till?: string;
}

export interface UserArchiveDto {
  archiveKey?: string;
  archiveSize?: number;
  batchDownloadAsArchive?: boolean;
  companyName?: string;
  companyUuid: string;
  direction?: Direction;
  downloadUrl?: string;
  entryPattern?: string;
  error?: string;
  expired?: boolean;
  expiryDate?: string;
  from?: string;
  id: string;
  includeAcknowledgment?: boolean;
  md5Hash?: string;
  messageIds?: string[];
  numFilesArchived?: number;
  numMessagesArchived?: number;
  state?: State;
  till?: string;
  timestamp: string;
}

export interface SortObject {
  empty?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
}

export interface PageableObject {
  offset?: number;
  paged?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sort?: SortObject;
  unpaged?: boolean;
}

export interface PageUserArchiveDto {
  content?: UserArchiveDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject;
  totalElements?: number;
  totalPages?: number;
}

export interface MessageStatusAttachmentDto {
  attachmentNumber?: number;
  attachmentNumberAsString?: string;
  messageId?: string;
  statusInformationNumber?: number;
}

export interface StatusInformationDownloadRequest {
  download?: boolean;
  prettyPrint?: boolean;
  sizeLimit?: number;
}

export type MessageMonitoringSortValuesItem = {[key: string]: any};

export type MessageMonitoringHighlights = {[key: string]: string[]};

export interface MessageMonitoring {
  admissibleCompanyUuids?: string[];
  documentReference?: string;
  documentTypeClassification?: string;
  documentTypeId?: string;
  highlights?: MessageMonitoringHighlights;
  inboundAddress?: string;
  inboundAppType?: string;
  inboundConnectorUuid?: string;
  inboundDocumentTypeId?: string;
  inboundMessageSize?: number;
  inboundNetwork?: string;
  inboundNumberOfDocuments?: number;
  inboundProtocol?: string;
  indexedAt?: string;
  lastError?: Failure;
  lastStatus?: string;
  lastStatusChange?: string;
  message?: Message;
  messageId?: string;
  messageSize?: number;
  mimeType?: string;
  numberOfDocuments?: number;
  outboundAddress?: string;
  outboundAppType?: string;
  outboundConnectorUuid?: string;
  outboundDocumentTypeId?: string;
  outboundMessageSize?: number;
  outboundNetwork?: string;
  outboundNumberOfDocuments?: number;
  outboundProtocol?: string;
  queryTime?: number;
  receivedAt?: string;
  receiverIdInMessage?: string;
  receiverIdInMessageName?: string;
  receiverInternalIdInMessage?: string;
  receiverInternalIdName?: string;
  receiverInternalIdUuid?: string;
  receiverName?: string;
  receiverUuid?: string;
  senderIdInMessage?: string;
  senderIdInMessageName?: string;
  senderInternalIdInMessage?: string;
  senderInternalIdName?: string;
  senderInternalIdUuid?: string;
  senderName?: string;
  senderUuid?: string;
  services?: string[];
  sortValues?: MessageMonitoringSortValuesItem[];
  stateChangedByUserId?: string;
  statusInformations?: StatusInformation[];
  version?: number;
}

export type UiPosition = (typeof UiPosition)[keyof typeof UiPosition];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UiPosition = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
} as const;

export type StatusInformationCustomHeaders = {
  [key: string]: {[key: string]: any};
};

export interface MessageStatusAttachment {
  attachmentNumber?: number;
  attachmentNumberAsString?: string;
  filename?: string;
  messageId?: string;
  mimeType?: string;
  statusInformationNumber?: number;
}

export interface StatusInformation {
  readonly apiErpelPassthrough?: string;
  readonly apiErpelPassthroughRaw?: string;
  readonly as2MessageId?: string;
  readonly as2ReceiverId?: string;
  readonly as2ReceiverName?: string;
  readonly as2SenderName?: string;
  readonly containerHostname?: string;
  readonly customHeaders: StatusInformationCustomHeaders;
  readonly deliveryConnectorUuid?: string;
  readonly documentReference?: string;
  readonly documentTypeId?: string;
  readonly erbOrderId?: string;
  readonly errorDetails?: Failure;
  readonly fileNameOnly?: string;
  readonly forwardConnectorUuid?: string;
  readonly inboundAs2MessageId?: string;
  readonly inboundX400OrderId?: string;
  readonly lastChangeDate?: string;
  readonly mdnMessageId?: string;
  readonly mendelsonAs2MessageId?: string;
  readonly messageId?: string;
  readonly messageSize?: number;
  readonly messageStatus?: string;
  readonly messageStatusAttachments?: MessageStatusAttachment[];
  readonly nodeHostname?: string;
  readonly numberOfDocuments?: number;
  readonly outboundAs2MessageId?: string;
  readonly position: UiPosition;
  readonly receiverConnectorUuid?: string;
  readonly relatedMessageId?: string;
  readonly resendAttemptNumber: number;
  readonly s3Bucket?: string;
  readonly s3Key?: string;
  readonly stateChangedByUserId?: string;
  readonly statusInformationId: number;
  readonly subject?: string;
  readonly timestamp?: string;
  readonly x400OrderId?: string;
}

export interface StatusInformationData {
  numberOfResendAttempts: number;
  statusInformations: StatusInformation[];
}

export interface Message {
  applicationReference?: string;
  communicationsAgreementId?: string;
  documentId?: string[];
  documentIdCount?: number;
  documentIdsAbbreviated?: string[];
  freeTexts?: string[];
  interchangeReference?: string;
  isTestIndicatorSet?: boolean;
  lineItemAdditionalCodes?: string[];
  lineItemCodes?: string[];
  lineItemDescription?: string[];
  messageDateTime?: string;
  messageId?: string;
  messageReceiverId?: string;
  messageSenderId?: string;
  nameAndAddressGln?: string[];
  nameAndAddressTxt?: string[];
  references?: string[];
}

export interface MessageMonitoringDetailsDto {
  admissibleCompanyUuids: string[];
  canResend: boolean;
  documentIdCount: number;
  documentReference?: string;
  documentTypeClassification?: string;
  documentTypeId?: string;
  hasSignatureHeader?: boolean;
  indexedAt: string;
  lastError?: Failure;
  lastStatus: string;
  lastStatusChange: string;
  lockedBy?: string;
  message: Message;
  messageId: string;
  messageSize?: number;
  nonFinalState?: boolean;
  numberOfDocuments: number;
  receivedAt: string;
  receiverIdInMessage?: string;
  receiverIdInMessageName?: string;
  receiverInternalIdInMessage?: string;
  receiverInternalIdName?: string;
  receiverInternalIdUuid?: string;
  receiverName?: string;
  receiverUuid?: string;
  senderIdInMessage?: string;
  senderIdInMessageName?: string;
  senderInternalIdInMessage?: string;
  senderInternalIdName?: string;
  senderInternalIdUuid?: string;
  senderName?: string;
  senderUuid?: string;
  stateChangedByUserId?: string;
  statusInformationData: StatusInformationData;
}

export type MessageMonitoringListDtoSortValuesItem = {[key: string]: any};

export interface Highlight {
  end?: number;
  start?: number;
  text?: string;
}

export type MessageMonitoringListDtoHighlights = {[key: string]: Highlight[]};

export interface Failure {
  details?: string;
  errorCode?: string;
  reason?: string;
  reference?: string;
}

export interface MessageMonitoringListDto {
  applicationReference?: string;
  documentIdCount?: number;
  documentIds?: string[];
  documentReference?: string;
  documentTypeClassification?: string;
  documentTypeId?: string;
  highlights?: MessageMonitoringListDtoHighlights;
  lastError?: Failure;
  lastStatus?: string;
  lastStatusChange?: string;
  locked?: boolean;
  lockedBy?: string;
  messageId?: string;
  numberOfDocuments?: number;
  receivedAt?: string;
  receiverIdInMessage?: string;
  receiverIdInMessageName?: string;
  receiverInternalIdName?: string;
  receiverName?: string;
  receiverUuid?: string;
  senderIdInMessage?: string;
  senderIdInMessageName?: string;
  senderInternalIdName?: string;
  senderName?: string;
  senderUuid?: string;
  sortValues?: MessageMonitoringListDtoSortValuesItem[];
}

export type DateFilterType =
  (typeof DateFilterType)[keyof typeof DateFilterType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateFilterType = {
  RECEIVED_AT: 'RECEIVED_AT',
  LAST_CHANGED_AT: 'LAST_CHANGED_AT'
} as const;

export type MessageSearchType =
  (typeof MessageSearchType)[keyof typeof MessageSearchType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageSearchType = {
  ALL: 'ALL',
  SENT: 'SENT',
  RECEIVED: 'RECEIVED'
} as const;

export type FilterMessageState =
  (typeof FilterMessageState)[keyof typeof FilterMessageState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilterMessageState = {
  ALL: 'ALL',
  SENT: 'SENT',
  EXCEPTION: 'EXCEPTION',
  CUSTOMER_EXCEPTION: 'CUSTOMER_EXCEPTION'
} as const;

export interface NewsFeedbackUserConfiguration {
  readonly enabled: boolean;
  readonly fetchSSOTokenUrl: string;
  readonly iFrameSandboxAttribute: string;
  readonly sourceUrl: string;
}

export interface SSOAuthenticationHolder {
  ssoToken?: string;
}

export interface PieChartSegment {
  id?: string;
  label?: string;
  value?: number;
}

export interface PieChartData {
  chart?: PieChartSegment[];
}

export type EdiPartnerEntryDocumentTypes = {[key: string]: number};

export interface EdiPartnerEntry {
  companyName?: string;
  companyUuid?: string;
  documentCount?: number;
  documentTypes?: EdiPartnerEntryDocumentTypes;
  messageCount?: number;
}

export interface EdiPartnerData {
  entries?: EdiPartnerEntry[];
}

export interface ExceptionData {
  customerExceptionCount?: number;
  exceptionCount?: number;
}

export interface PointStringLong {
  x?: string;
  y?: number;
}

export interface ChartLine {
  data?: PointStringLong[];
  id?: string;
  sum?: number;
}

export interface LineChartData {
  chart?: ChartLine[];
}

export type MessageStatisticsRowMessageCounts = {
  [key: string]: DirectionalMessageCount;
};

export interface MessageStatisticsRow {
  alias?: string;
  companyId?: string;
  companyName?: string;
  messageCounts?: MessageStatisticsRowMessageCounts;
  partnerName?: string;
  partnerUuid?: string;
  totalDocumentCount?: number;
  totalMessageCount?: number;
}

export type MessageStatisticsRows = {[key: string]: MessageStatisticsRow[]};

export type MessageStatisticsDocumentTypes = {[key: string]: number};

export interface MessageStatistics {
  documentTypes?: MessageStatisticsDocumentTypes;
  rows?: MessageStatisticsRows;
}

export interface DirectionalMessageCount {
  documentsReceived?: number;
  documentsSent?: number;
  documentsTotal?: number;
  messagesReceived?: number;
  messagesSent?: number;
  messagesTotal?: number;
}

export type Direction = (typeof Direction)[keyof typeof Direction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Direction = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING'
} as const;

export type Period = (typeof Period)[keyof typeof Period];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Period = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  THIS_WEEK: 'THIS_WEEK',
  LAST_24_HOURS: 'LAST_24_HOURS',
  LAST_7_DAYS: 'LAST_7_DAYS',
  LAST_30_DAYS: 'LAST_30_DAYS',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  LAST_3_MONTHS: 'LAST_3_MONTHS',
  THIS_YEAR: 'THIS_YEAR',
  LAST_YEAR: 'LAST_YEAR'
} as const;

export interface PartnerAndMappingData {
  numberOfEdiPartners?: number;
  numberOfMappings?: number;
}

export type AutoCompleteSuggestionMeta = {[key: string]: {[key: string]: any}};

export interface AutoCompleteSuggestion {
  description?: string;
  disabled?: boolean;
  icon?: string;
  meta?: AutoCompleteSuggestionMeta;
  text?: string;
  value?: string;
}

export type UserStatus = (typeof UserStatus)[keyof typeof UserStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserStatus = {
  AWAY: 'AWAY',
  AVAILABLE: 'AVAILABLE'
} as const;

export type CompanyType = (typeof CompanyType)[keyof typeof CompanyType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyType = {
  STANDARD: 'STANDARD',
  DEPARTMENT: 'DEPARTMENT',
  EXTERNAL: 'EXTERNAL',
  TEST: 'TEST',
  OWN: 'OWN',
  PARTNER: 'PARTNER'
} as const;

export interface UserResponse {
  avatarUrl?: string;
  company: string;
  companyType: CompanyType;
  email: string;
  firstName?: string;
  lastName?: string;
  role: string;
  status: UserStatus;
}

export interface ClientCredentialsDto {
  clientId: string;
  clientSecret: string;
}

export type EInvoiceConnectorSetupState =
  (typeof EInvoiceConnectorSetupState)[keyof typeof EInvoiceConnectorSetupState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EInvoiceConnectorSetupState = {
  EMPTY: 'EMPTY',
  DRAFT: 'DRAFT',
  CONFIGURED: 'CONFIGURED',
  ERROR: 'ERROR'
} as const;

export type Country = (typeof Country)[keyof typeof Country];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Country = {
  HUNGARY: 'HUNGARY',
  ISRAEL: 'ISRAEL',
  ITALY: 'ITALY',
  MALAYSIA: 'MALAYSIA',
  TURKEY: 'TURKEY',
  SPAIN: 'SPAIN',
  POLAND: 'POLAND',
  ROMANIA: 'ROMANIA',
  SAUDI_ARABIA: 'SAUDI_ARABIA'
} as const;

export interface EInvoiceConnectorDetailsDto {
  accessTokenExpiry?: string;
  callbackUrl?: string;
  clientId?: string;
  clientSecret?: string;
  country?: Country;
  encryptionKey?: string;
  refreshTokenExpiry?: string;
  state?: EInvoiceConnectorSetupState;
  uuid?: string;
}

export type Connector = (typeof Connector)[keyof typeof Connector];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Connector = {
  API: 'API',
  AS2: 'AS2',
  CAMEL_ENDPOINT: 'CAMEL_ENDPOINT',
  EINVOICE: 'EINVOICE',
  FTP: 'FTP',
  HTTP: 'HTTP',
  MASTERDATA: 'MASTERDATA',
  OFTP2: 'OFTP2',
  PEPPOL: 'PEPPOL',
  SFTP_SERVER: 'SFTP_SERVER',
  SMTP: 'SMTP',
  SUPPLY: 'SUPPLY',
  WEBEDI: 'WEBEDI',
  X400: 'X400'
} as const;

export interface UserKeyRegeneratedResponse {
  connector?: Connector;
  copyOf?: string;
  direction?: ConnectorType;
  userKey?: string;
  uuid?: string;
  version?: number;
}

export interface UserKeyUpdate {
  connectorUuid?: string;
  version?: number;
}

export type ConnectorType = (typeof ConnectorType)[keyof typeof ConnectorType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConnectorType = {
  FORWARDER: 'FORWARDER',
  RECEIVER: 'RECEIVER',
  DELIVERY: 'DELIVERY',
  LOCAL_STATION: 'LOCAL_STATION',
  FETCH: 'FETCH',
  VIRTUAL_LOCAL_STATION: 'VIRTUAL_LOCAL_STATION',
  SENDER_SPECIFIC_FORWARDER: 'SENDER_SPECIFIC_FORWARDER'
} as const;

export interface SftpServerUpdateResponse {
  copyOf?: string;
  direction?: ConnectorType;
  password?: string;
  uuid?: string;
  version?: number;
}

export interface PasswordUpdate {
  connectorUuid?: string;
  version?: number;
}

export interface SelectSubCompany {
  selectedSubCompany: string;
}
