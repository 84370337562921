import React, {PropsWithChildren, Suspense, useEffect} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import {Loader} from 'semantic-ui-react';

import {getAccountsBaseUrl} from '@/helpers/urls';
import {ComponentErrorBoundary, ToastContainer} from '@ecosio/components';
import {useIntl} from 'react-intl';
import AsyncPage from './AsyncPage';
import DownloadProgressIndicator from './components/DownloadStatusIndicator/DownloadProgressIndicator';
import Layout from './components/Layout/Layout';
import SelectPartnerRelationModal from './components/PartnerRelations/SelectPartnerRelationModal';
import {useAppSelector} from '@/hooks';
import {History} from 'history';

export const Routes = {
  MESSAGE_DETAIL: '/messages/:uuid'
};

const TICKET_URL_PREFIX = '/tickets';
export const SUPPORT_TICKETS_URL = `${TICKET_URL_PREFIX}/support`;
export const ONBOARDING_TICKETS_URL = `${TICKET_URL_PREFIX}/onboarding`;
export const TICKET_CREATE_URL = `${TICKET_URL_PREFIX}/create`;
export const TICKET_DETAIL_URL = `${TICKET_URL_PREFIX}/:id`;

export const TICKET_EDIT_URL = `${TICKET_URL_PREFIX}/:id/edit`;

export const EDI_RELATIONS_URL = '/edi-relations';

export const NEW_EDI_RELATIONS_URL = '/edi-relations/v2';

export const createTicketDetailsUrl = (internalTicketId: string | number) => {
  return `${TICKET_DETAIL_URL.replace(':id', `${internalTicketId}`)}`;
};

export const createTicketCommentUrl = (
  internalTicketId: string,
  commentId: string
) => {
  return `${TICKET_DETAIL_URL.replace(
    ':id',
    `${internalTicketId}`
  )}#${commentId}`;
};

export const createTicketEditUrl = (internalTicketId: number) => {
  return `${TICKET_EDIT_URL.replace(':id', `${internalTicketId}`)}`;
};

const RoutesGuardWrapper: React.FC<PropsWithChildren> = ({children}) => {
  const mfaEnforcedOnUser = useAppSelector(
    state => state.config?.userConfig?.mfaEnforcedOnUser
  );
  const intl = useIntl();

  useEffect(() => {
    if (mfaEnforcedOnUser) {
      window.location.href = `${getAccountsBaseUrl()}/settings`;
    }
  }, [intl, mfaEnforcedOnUser]);

  return <>{children}</>;
};

/**
 * *************** IMPORTANT ***********
 * When you change/remove/add routes here, you need to fix the nginx.conf
 * file for this app in the backend/src/main/resources folder.
 *
 */
const AppRouter: React.FC<{history: History}> = ({history}) => {
  return (
    <Router history={history}>
      <SelectPartnerRelationModal />
      <Layout>
        <ToastContainer position="top-center" onlyShowLast dataSpec="toast" />
        {/*Download Indicator shows if the message download compressing process is done or not*/}
        <ComponentErrorBoundary silent>
          <DownloadProgressIndicator />
        </ComponentErrorBoundary>
        <RoutesGuardWrapper>
          <Suspense fallback={<Loader active />}>
            <Switch>
              <Route
                exact
                path="/"
                render={props => (
                  <AsyncPage componentName="DashboardPage" {...props} />
                )}
              />
              <Route
                exact
                path="/messages"
                render={props => (
                  <AsyncPage componentName="MessageList" {...props} />
                )}
              />
              <Route
                exact
                path="/messages/search"
                render={props => (
                  <AsyncPage componentName="MessageSearchResults" {...props} />
                )}
              />
              <Route
                exact
                path={Routes.MESSAGE_DETAIL}
                render={props => (
                  <AsyncPage componentName="MessageDetails" {...props} />
                )}
              />
              <Route
                exact
                path="/masterdata/assemblies"
                render={props => (
                  <AsyncPage componentName="AssemblyListPage" {...props} />
                )}
              />
              <Route
                exact
                path="/user-archives"
                render={props => (
                  <AsyncPage componentName="UserArchiveListPage" {...props} />
                )}
              />
              <Route
                exact
                path="/connectors"
                render={props => (
                  <AsyncPage componentName="ApiConnectorsPage" {...props} />
                )}
              />
              <Route
                exact
                path="/connectors/oauth2/configure/:uuid"
                render={props => (
                  <AsyncPage
                    componentName="OAuth2EInvoiceConnectorConfigPage"
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/connectors/configure/:uuid"
                render={props => (
                  <AsyncPage
                    componentName="SimpleEInvoiceConnectorConfigPage"
                    {...props}
                  />
                )}
              />

              <Route
                exact
                path="/statistics"
                render={props => (
                  <AsyncPage componentName="StatisticsPage" {...props} />
                )}
              />

              <Route
                exact
                path={ONBOARDING_TICKETS_URL}
                render={props => (
                  <AsyncPage componentName="PartnerMatrixPage" {...props} />
                )}
              />

              <Route
                exact
                path={SUPPORT_TICKETS_URL}
                render={props => (
                  <AsyncPage componentName="SupportTicketsPage" {...props} />
                )}
              />

              <Route
                exact
                path={TICKET_CREATE_URL}
                render={props => (
                  <AsyncPage componentName="TicketCreatePage" {...props} />
                )}
              />

              <Route
                path={TICKET_DETAIL_URL}
                render={props => (
                  <AsyncPage componentName="TicketDetailsPage" {...props} />
                )}
              />

              <Route
                path={EDI_RELATIONS_URL}
                render={props => (
                  <AsyncPage componentName="EDIRelationsPage" {...props} />
                )}
              />

              <Route
                path="/debug"
                render={props => (
                  <AsyncPage componentName="DebugPage" {...props} />
                )}
              />

              <Route
                path="/markdown-documentation"
                render={props => (
                  <AsyncPage
                    componentName="MarkdownDocumentationPage"
                    {...props}
                  />
                )}
              />

              <Route
                path="*"
                render={props => (
                  <AsyncPage componentName="PageNotFound" {...props} />
                )}
              />
            </Switch>
          </Suspense>
        </RoutesGuardWrapper>
      </Layout>
    </Router>
  );
};

export default AppRouter;
